<template>
  <div class="elegantDemeanor">
    <div class="title">课程分类</div>
    <div class="parent">
      <div v-for="item in courseList" :key="item.id" class="elegantBox" @click="getDetail(item)">
        <!--      图片-->
        <div class="elegantImg">
          <!--            <img :src="$downloadUrl+item.fileUrls" alt="">-->
          <el-image
            style="width: 285px;height: 163px;"
            :src="$downloadUrl+item.fileUrls"
            fit="cover"
          />
        </div>
        <!--      小矩形-->
        <div class="elegantBo">
          <p>{{ item.title }}</p>
          <el-button type="primary">查看</el-button>
        </div>
      </div>
    </div>
    <!--      <div class="pic-box">
               <div class="waterfull-box">
                <vue-waterfall-easy :imgsArr="picList" :imgWidth="390" :maxCols="3" :imgHeight="390" :gap="10" :loadingDotCount='0' class="test">
                    <div slot-scope="props"
                             style="padding: 10px;color: #666666;overflow: hidden"
                      >
                         <el-tooltip class="item" effect="dark" :content="props.value.info" placement="bottom">
                        <div>{{props.value.title}}</div>
                        </el-tooltip>
                     </div>

                 </vue-waterfall-easy>
            </div>&ndash;&gt;
        </div>-->
  </div>

</template>

<script>
// import vueWaterfallEasy from 'vue-waterfall-easy'
export default {
  name: 'ElegantDemeanor',
  components: {
    // vueWaterfallEasy
  },
  props: {
    courseList: Array,
    isLogin: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {

    }
  },
  methods: {
    getDetail(data) {
      if (this.isLogin === 0) {
        // type:（0公司动态，1课程宣传）
        this.$router.push({
          path: '/DynamicUnlogin',
          query: {
            id: data.id,
            type: 1
          }
        })
      } else {
        // type:（0公司动态，1课程宣传）
        this.$router.push({
          path: '/Dynamicpages',
          query: {
            id: data.id,
            type: 1
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.elegantDemeanor {
  overflow: hidden;

  .title {
margin-top: 10px;
    margin-bottom: 11px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 21px;
    text-align: left;

    img {
      //padding-right: 6px;
    }
  }

  .parent{
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    .elegantBox:nth-of-type(4n+0){
      margin-right: 0px;
    }
  }

  .elegantBox {
    border-radius: 10px;
    overflow: hidden;
    //position: relative;
    //float: left;
    width: 285px;
    height: 215px;
    margin-bottom: 20px;
    margin-right: 20px;
    //padding-top: 20px;
    transition: all 0.2s;
    &:hover{
      transform: translateY(-10px);
      cursor: pointer;
    }
    //图片
    .elegantImg {
      height: 158px;
      //background-color: #e27979;

      img {
        width: 100%;
        height: 100%;
      }
    }
    //小矩形
    .elegantBo {
      //position: absolute;
      //top: 279px;
      //left: 13px;
      height: 52px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      overflow: hidden;
      position:relative;
      border-radius: 0 0 10px 10px;
      p {
        width: 187px;
        margin: 18px 20px 15px 15px;
        //height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 19px;
        float: left;
        text-align: left;
      }

      /deep/ .el-button {
        //float: left;
        margin: 16px 16px 13px 0;
        background: #4C97FD;
        border-radius: 14px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        width: 50px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

     /* /deep/ .el-button--primary {
        margin-top: 10px;
        width: 79px;
        height: 23px;
        background: rgba(67, 146, 253, 1);
        border-radius: 15px;
      }*/
    }

  }

  //.pic-box{
  //    position: relative;
  //    width: 100%;
  //    height: 1000px;
  //    ::v-deep.waterfull-box{
  //        position: absolute;
  //        top:6px;
  //        bottom:0;
  //        width:100%;
  //        .vue-waterfall-easy-scroll{
  //            overflow-y: auto;
  //        }
  //    }
  //}
}

</style>
