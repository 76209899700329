<template>
  <!--大转盘入口-->
  <div>
    <!-- 大图 -->
    <el-dialog
      class="dialog-box"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <i class="el-icon-circle-close close-icon" @click="show(false)" />
      <img src="../../assets/images/activity/zhuanpan.png" alt="" @click="handleClickImg">
    </el-dialog>
    <!-- 右下小图 -->
    <aside v-if="asideVisible" class="aside-box">
      <i class="el-icon-circle-close close-icon" @click="asideVisible=false" />
      <img src="../../assets/images/activity/zhuanpan_small.png" alt="" @click="handleClickImg">
    </aside>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {getActivityStatus} from "../../api/activity";

export default {
  name: 'AdvertisingBounced',
  data() {
    return {
      dialogVisible: false,
      asideVisible: false
    }
  },
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  watch: {
    dialogVisible(val) {
      this.asideVisible = !val
    }
  },
  created() {


    this.queryActivityStatus()
  },
  methods: {

    queryActivityStatus(){
      const newTime = this.$moment(new Date()).format('YYYY-MM-DD')
      const oldTime = localStorage.getItem('AdvertisingTime')
      this.$store.dispatch('QUERY_ACTIVITY_STATUS').then(status=>{
          // status 活动是否开始  true开始 false结束 返回时字符串需要用JSON转换下
          if (newTime !== oldTime && status) {
            localStorage.setItem('AdvertisingTime', newTime)
            this.show(true)
          }
          this.asideVisible=status
      })
    },
    show(isOpen) {
      this.dialogVisible = isOpen
    },
    handleClickImg() {
      this.show(false)
      if (this.token) {
        const routeData = this.$router.resolve({path: '/turntable'})
        window.open(routeData.href, '_blank')
      } else {
        this.$emit('handleClickImg')
      }
    }
  }
}
</script>

<style scoped lang="less">
.dialog-box{
  /deep/ .el-dialog__header{
    display: none;
  }
  /deep/ .el-dialog{
    margin-top: 0!important;
    margin-bottom: 0!important;
    background-color: transparent!important;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh!important;
    &::-webkit-scrollbar{
      width: 0;
      height: 0;
    }
    .el-dialog__body{
      padding: 0 70px 0 0;
      position: relative;
      img, i{
        cursor: pointer;
      }
      img{
        width: 450px;
        user-select: none;
      }
      .close-icon{
        position: absolute;
        top: 90px;
        right: 50px;
        font-size: 30px;
        color: white;
      }
    }
  }
}
.aside-box{
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 377px;
  z-index: 2;
  i{
    position: absolute;
    top: 20px;
    right: 75px;
    font-size: 30px;
    z-index: 2;
  }
  img, i{
    cursor: pointer;
    user-select: none;
  }
}
</style>
