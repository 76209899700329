<template>
  <div class="welcomeBox">
    <div class="welcomeBox-content">
      <i class="el-icon-circle-close welcomeBox-content-icon" @click="close()" />
      <div class="welcomeBox-content-body">
        <div class="welcomeBox-content-title">你好，{{ name }}同学，欢迎来到教学管理云平台</div>
        <div class="welcomeBox-content-subtitle">登录系统进行学习吧!</div>
        <div class="welcomeBox-content-footer">
          <div class="welcomeBox-content-footer-text">初次请使用验证码登陆</div>
          <div class="welcomeBox-content-footer-btn" @click="close(true)">去登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Base64 = require('js-base64').Base64
export default {
  name: 'WelcomeBox',
  data() {
    return {
      name: ''
    }
  },
  created() {
    this.name = Base64.decode(this.$route.query.name || '') // 解密
  },
  methods: {
    close(isLogin = false) {
      this.$emit('welcomeClose', isLogin)
    }
  }
}
</script>

<style scoped lang="less">

.welcomeBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  display: flex;
  flex-direction: column-reverse;

  &-content {
    width: 100%;
    height: 639px;

    &-icon {
      color: #e1e1e1;
      font-size: 38px;
      font-weight: 100;
      margin-bottom: 73px;
      cursor: pointer;
    }

    &-body {
      width: 100%;
      height: 550px;
      background-image: url(../../../assets/images/欢迎底部.png);
      background-size: 100% 100%;
      position: relative;
    }

    &-title {
      font-size: 45px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e1e1e1;
    }

    &-subtitle {
      margin-top: 10px;
      font-size: 35px;
      color: #e1e1e1;
    }

    &-footer {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 35px;
      display: flex;
      align-items: center;

      &-text {
        font-weight: 400;
        color: #FCD226;
        font-size: 26px;
      }

      &-btn {
        cursor: pointer;
        width: 200px;
        height: 48px;
        background: #ED603D linear-gradient(360deg, #FCD226 0%, #FCD226 100%, #F7F662 200%);
        box-shadow: 0 4px 0 0 #EA3315;
        border-radius: 30px;
        line-height: 48px;
        margin-left: 20px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4E3F00;
      }
    }
  }
}
</style>
