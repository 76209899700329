<template>
  <div class="companyDynamics">
    <div class="title">公司动态</div>
    <div class="companyDynamicsBox">
      <div v-for="(i,index) in DynamicsList" :key="index" class="dynamicsList">
        <div style="cursor: pointer" @click="getDetail(i)">
          <div class="cdBox" :body-style="{ padding: '0px', marginBottom: '1px'}" shadow="hover">
            <!--              图片-->
            <div class="Img">
              <!--              <img :src="$downloadUrl+i.fileUrls" class="image" v-if="i.fileUrls"/>-->
              <el-image
                v-if="i.fileUrls"
                style="width: 386px;
          height: 185px;"
                :src="$downloadUrl+i.fileUrls"
                fit="cover"
              />
            </div>
            <!--              文字-->
            <div class="Content" style="background-color: #fff;height: 77px;">
              <div class="everyTitle">{{ i.title }}</div>
              <!--              <div class="everyIntro">{{i.introduction}}</div>-->
              <!--                <p v-html="i.introduction"></p>-->
              <div class="everyTime">{{ i.releaseTime==null||i.releaseTime==''?'':i.releaseTime.substring(0,10) }}</div>
              <!--                <div class="everyTime">{{ i.createTime==null||i.createTime==''?'':i.createTime.substring(0,10) }}</div>-->
            </div>
            <!--                <img src="../assets/images/empty.png" class="image" v-else/>
                  <div style="padding: 14px;height: 50px">
                    <div class="text" :title="i.title">{{i.title}}</div>
                  </div>-->
          </div>
        </div>
      </div>
    </div>
    <!--    详细信息-->
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
    >
      <p style="margin: 20px 0px">{{ dynamicsDetail.title }}</p>
      <div class="create">
        <p style="color: #999093;margin: 15px 0px">创建时间：{{ dynamicsDetail.createTime }}</p>
        <p style="color: #999093;margin: 15px 0px">创建人：{{ dynamicsDetail.createBy }}</p>
      </div>
      <div style="width: 100%">
        <div class="content" v-html="dynamicsDetail.content">{{ dynamicsDetail.content==''?'暂无内容':'' }}</div>
      </div>

    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'CompanyDynamics',
  props: {
    DynamicsList: Array,
    isLogin: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      dialogVisible: false,
      dynamicsDetail: {
        title: '标题标题',
        createdTime: '2020-10-11 12:23',
        createBy: '张三丰',
        content: '<p>这是一段富文本内容</p>'
      }
    }
  },
  created() {
  },
  methods: {
    getDetail(data) {
      if (this.isLogin === 0) {
        // type:（0公司动态，1课程宣传）
        this.$router.push({
          path: '/DynamicUnlogin',
          query: {
            id: data.id,
            type: 0
          }
        })
      } else {
        // type:（0公司动态，1课程宣传）
        this.$router.push({
          path: '/Dynamicpages',
          query: {
            id: data.id,
            type: 0
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">

  /*.el-col-lg-4-8 {*/
  /*  width: 20%;*/
  /*}*/
  .companyDynamics{
    margin: 30px auto 0px;
    .title{
      font-size: 16px;
      font-weight: bold;
      font-family: Microsoft YaHei;
      color: #333333;
      text-align: left;
      margin-bottom: 20px;
      img{
        //padding-right: 6px;
      }
    }
    .companyDynamicsBox{
      display: flex;
      //justify-content: space-between;
      flex-wrap: wrap;
      .dynamicsList{
        width: 386px;
        height: 262px;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 10px;
        margin-right: 20px;
        transition: all 0.2s;
        &:hover{
          transform: translateY(-10px);
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        //图片
        .Img{
          //float: left;
          width: 386px;
          height: 185px;
          img {
            width: 500px;
            margin-left: -70px;
          }
        }
        //文字
        .Content{
          width: 386px;

          //float: left;
          p:first-child{
            text-align: left;
            height: 24px;
            color: rgba(48, 48, 48, 1);
            font-size: 16px;
            font-weight: bold;
            margin-top: 28px;
            overflow: hidden;
          }
          p:nth-of-type(2){
            width: 245px;
            height: 48px;
            color: rgba(125, 125, 125, 1);
            font-size: 14px;
            text-align: left;
            margin-top: 20px;
            overflow: hidden;

          }
          p:last-child{
            height: 18px;
            color: rgba(155, 155, 155, 1);
            font-size: 12px;
            text-align: left;
            margin-top: 22px;
          }
        }
        .image{
          width: 100%;
          height: 182px;
        }
      }
    }

      //.dynamicsList{
      //  height: 239px;
      //  margin:10px 0;
      //  .image{
      //    width: 100%;
      //    height: 168px;
      //  }
      //  ::v-deep.el-card{
      //    width: 100%;
      //    height: 100%;
      //    border: none;
      //    .text{
      //      font-size: 14px;
      //      font-family: Microsoft YaHei;
      //      font-weight: 400;
      //      color: #666666;
      //      display: -webkit-box;
      //      -webkit-box-orient: vertical;
      //      -webkit-line-clamp: 1;
      //      overflow: hidden;
      //      text-overflow:ellipsis;
      //    }
      //  }
      //}
    ::v-deep.el-dialog{
      overflow: auto;
      .el-dialog__body{
        padding-top: 10px;
        p{
          text-align: center;
          font-size: 20px;
        }
        .create{
          display: flex;
          justify-content: space-around;
          //flex-wrap: wrap;
          p{
            font-size: 15px;
          }
        }
        .content{
          padding-top: 10px;
          min-height: 200px;
          width: 100%;
          overflow: auto;
          img{
            width: 100%;
          }
          p{
            margin: 15px;
          }
        }
      }

    }
  }
/*  @media screen and (min-width: 1200px) {
    .el-col-lg-4-8 {
      width: 20%;
    }
  }*/
  .everyTitle{
    padding: 15px 16px 10px 15px;
    text-align: left;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: #333333;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  }
  .everyIntro{
    color: #8e8e8e;
    margin: 10px;
    text-align: left;
    font-size: 14px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:4;
    min-height: 77px;
  }
  .everyTime{
   padding-bottom: 15px;
    margin-left: 15px;

    text-align: left;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #999999;
    line-height: 16px;
  }
</style>
