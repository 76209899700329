<template>
  <!--  未登录-->
  <div class="index">
    <IndexHeader>
      <div class="tab">
        <div class="select tabLi">首页</div>
        <div class="stick" />
        <div class="tabLi" @click="toLogin">我要学习</div>
        <div class="stick" />
        <div class="tabLi" @click="toLogin">课程作业</div>
        <div class="stick" />
        <div class="tabLi" @click="toLogin">难题解答</div>
        <div class="stick" />
        <div class="tabLi" @click="toLogin">基本功测评</div>
      </div>
      <!-- 铃铛 -->
      <div class="Bell" @click="toLogin">
        <i class="el-icon-message-solid" />
      </div>
      <!-- 边框 -->
      <div class="stick" style="margin-left: 20px;" />
      <!-- 登录 -->
      <div class="HeaderLogin">
        <div class="Login" @click="toLogin"><a href="#">登录</a></div>
      </div>
      <div class="HeaderLogin">
        <div class="Login" @click="applyFor"><a href="#">{{ isNeedExamine?'试学':'注册' }}</a></div>
      </div>
    </IndexHeader>
    <div class="topBox">
      <!-- 右侧固定栏 -->
      <div class="rightTab">
        <div class="box">
          <div class="feedback">
            <div class="before" style="width: 68px;height: 72px;">
              <img src="../../assets/images/feed_default.png" alt="">
            </div>
            <div class="after" style="width: 68px;height: 72px;" @click="toLogin">
              <img src="../../assets/images/feed_selected.png" alt="">
            </div>
          </div>
        </div>
        <div v-for="(item,index) in qqList" :key="index">
          <div class="box">
            <div class="qqbox">
              <div class="before" style="width: 68px;height: 72px;">
                <img src="../../assets/images/qq_default.png" alt="">
              </div>
              <a :href="item" target="_blank">
                <img src="../../assets/images/qq_selected.png" alt="">
                <!-- <div class="after" style="border-radius: 6px 6px 0 0"><span>联系<br>客服</span></div>-->
              </a>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="feedback">
            <div class="before" style="width: 68px;height: 72px;">
              <img src="../../assets/images/try_default.png" alt="">
            </div>
            <div class="after" style="width: 68px;height: 72px;" @click="applyFor">
              <img src="../../assets/images/try_selected.png" alt="">
            </div>
            <!--  <div class="after" style="border-radius: 6px 6px 0 0" @click="applyFor"><span>试学<br>申请</span></div>-->
          </div>
        </div>
        <div v-if="isShowGoTop" class="box">
          <div class="top">
            <i class="el-icon-top before" />
            <div class="after" style="border-radius:0 0 6px 6px">
              <a href="javascript:scroll(0,0)" style="border-radius:0 0 6px 6px;background-color: #157dfb">返回<br>顶部</a>
            </div>
          </div>
        </div>
      </div>
      <!--          轮播图-->
      <div class="slideShow">
        <el-carousel :interval="5000" arrow="always" autoplay height="335px">
          <el-carousel-item v-for="item in picList" :key="item.url">
            <div
              class="Img"
              :style="`background-image: url(${$downloadUrl+item.url})`"
              @click="getLoopDetail(item)"
            />
            <!--            <img :src="$downloadUrl+item.url" alt="" style="width: 100%;height: 360px" >-->
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--          中间部分-->
      <div id="middle" class="middle">
        <!--            公司动态-->
        <div class="dynamics">
          <companyDynamics :dynamics-list="DynamicsList" :is-login="0" />
        </div>
        <!--            课程分类-->
        <div class="publicity">
          <elegantDemeanor :course-list="courseList" :is-login="0" />
        </div>
      </div>
      <indexBottom />
    </div>
    <el-dialog :visible.sync="loopShow" width="800px">
      <p style="margin: 10px 0px;font-size: 20px">{{ carouselTitle }}</p>
      <div class="content" style="padding-top: 20px;" v-html="detailData" />
    </el-dialog>
    <!--            这是弹出层模态框-->
    <loginDialog
      :dialog-visible="dialogVisible"
      :dialogpass-visible="dialogpassVisible"
      @dialogPassClose="dialogPassClose"
      @dialogClose="dialogClose"
    />
    <WelcomeBox v-if="welcomeVisible" @welcomeClose="welcomeClose" />
    <AdvertisingBounced ref="AdvertisingBounced" @handleClickImg="toLogin" v-if="" />
  </div>
</template>

<script>
import elegantDemeanor from '../index/components/elegantDemeanor'
import indexBottom from '../../components/indexBottom'
import companyDynamics from '../../components/companyDynamics'
import loginDialog from './components/loginDialog'
import { getCourseList, getDynamicsList } from '@/api/welcome'
import IndexHeader from '@/components/IndexHeader'
import AdvertisingBounced from '@/components/activity/AdvertisingDialog'
import WelcomeBox from '@/views/unLogin/components/WelcomeBox'
import { getConfigKey } from '@/api/login'

export default {
  name: 'Index',
  components: { WelcomeBox, indexBottom, companyDynamics, loginDialog, elegantDemeanor, IndexHeader, AdvertisingBounced },
  data() {
    return {
      welcomeVisible: this.$route.query.name || false,
      detailData: '',
      loopShow: false,
      // 轮播图标题
      carouselTitle: '',
      phone: '',
      dialogVisible: false,
      dialogpassVisible: false,
      courseList: [],
      DynamicsList: [],
      picList: [],
      activeName: 'first',
      isShowGoTop: false,
      qqList: [],
      // 是否需要申请
      isNeedExamine: false,

    }
  },
  //  监听
  watch: {
    dialogVisible(res) {
      this.$store.commit('changeDialogVisible', res)
    }
  },
  created() {
    this.getNeedExamine()
    this.getDynamicsList()
    this.getCourseList()
    this.$store.dispatch('GET_DICT_LIST')
    // 处理qq客服的请求地址
    this.dealWithQQList()
    this.phone = this.$store.state.dict.dictTree.es_call['remark'].toString()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {

    /* 是否需要申请*/
    getNeedExamine() {
      getConfigKey().then(res => {
        this.isNeedExamine = res.msg === 'true'
      })
    },
    welcomeClose(isLogin) {
      this.welcomeVisible = false
      isLogin && this.toLogin()
    },
    handleClick(tab, event) {
      console.log(tab, event)
      this.dialogVisible = true
    },
    // 申请弹框
    applyFor() {
      this.dialogpassVisible = true
    },
    getDynamicsList() {
      getDynamicsList({ pageSize: 10, pageNum: 1, isLoopShow: 0 }).then(res => {
        if (res.code === 200) {
          this.picList = res.rows.map(i => ({
            url: i.loopUrls,
            id: i.id,
            content: i.content,
            title: i.title
          }))
          this.picList = this.picList.filter((i) => {
            if (i.url !== '') {
              return i
            }
          })
        }
      })
      getDynamicsList({ pageSize: 10, pageNum: 1, isShow: 0 }).then(res => {
        if (res.code === 200) {
          this.DynamicsList = res.rows
        }
      })
    },
    getCourseList() {
      getCourseList({}).then(res => {
        if (res.code === 200) {
          this.courseList = res.rows
        }
      })
    },
    toLogin() {
      this.dialogVisible = true
    },
    dialogClose() {
      this.dialogVisible = false
    },
    dialogPassClose(data) {
      console.log(data)
      this.dialogpassVisible = data
    },
    getLoopDetail(data) {
      console.log(data)
      // type:（0公司动态，1课程宣传）
      this.$router.push({
        path: '/DynamicUnlogin',
        query: {
          id: data.id,
          type: 0
        }
      })
    },
    // 获取轮播图详情
    getDetail() {
      const activeIndex = this.$refs.carousel.activeIndex
      this.detailData = this.picList[activeIndex].content
      this.carouselTitle = this.picList[activeIndex].title
      this.loopShow = true
    },
    handleScroll() {
      // 滚动条滚动时，距离顶部的距离
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 判断是否下拉了所需长（高）度，若满足，则返回顶部图标出现
      if (this.scrollTop > 450) {
        this.isShowGoTop = true
      } else {
        this.isShowGoTop = false
      }
    },
    // 处理qq客服的请求地址
    dealWithQQList() {
      this.qqList = this.$store.state.dict.dictTree.es_call['remark']
      this.qqList.forEach(function(item, index, arr) {
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
          arr[index] = '/openqq.html?qqNum=' + item
        } else {
          arr[index] = 'http://wpa.qq.com/msgrd?v=3&uin=' + item + '&site=qq&menu=yes'
        }
      })
      console.log(this.qqList)
    }
  }
}
</script>

<style scoped lang="less">

.read {
  color: rgba(0, 0, 0, 0.25);;
}

.Bell {
  float: left;
  //margin-left: 284px;
  margin: 23px 0 24px 208px;
  width: 14px;
  height: 18px;
  line-height: 25px;
  color: #4C97FD;
  cursor: pointer;
  //border-right: 1px solid #E7EDF6;
  //padding-right: 21px;
}

.tab {
  margin-left: 122px;

  .tabLi {
    &:hover {
      cursor: pointer;
    }
  }

  .select {
    width: 100px;
    height: 30px;
    color: rgba(67, 146, 253, 1) !important;
    font-weight: bold;
  }

}

.stick {
  float: left;
  margin-top: 27px;
  //width: 0px;
  height: 16px;
  border-right: 1px solid #E7EDF6;
  //margin-left: 21px;
}

//  登录
.HeaderLogin {
  float: left;
  overflow: hidden;
  margin-top: 24px;
  margin-left: 18px;
  font-family: MicrosoftYaHei;

  .Login {
    float: left;
    color: #333333;
  }

  .Resign {
    float: right;
    font-size: 15px;
  }
}

.slideShow {
  margin: auto;
  height: 335px;
  margin-top: 70px;

  .Img {
    height: 335px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    cursor: pointer;
  }

}

.index {
  height: 100%;

  .rightTab {
    position: fixed;
    right: 30px;
    top: 340px;
    width: 68px;
    //height: 152px;
    background: #FFFFFF;
    //border: 1px solid #E6E9EF;
    border-radius: 6px;
    z-index: 1999;

    .box {
      width: 68px;
      height: 72px;
      border-bottom: 1px solid #E6E9EF;
      overflow: hidden;
      position: relative;
    }

    .before {
      color: #CBD1D5;
      text-align: center;
      font-size: 20px;
      line-height: 72px;
    }

    .after, a {
      background: #39A5EF;
      width: 68px;
      height: 72px;
      font-size: 15px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }

    .feedback, .phoneNum, .qqbox {
      width: 68px;
      height: 144px;

      &:hover {
        cursor: pointer;
        transform: translate(0, -50%);
      }
    }

    .top {
      width: 68px;
      height: 144px;

      &:hover {
        transform: translate(0, -50%);
      }
    }
  }

  > .topBox {
    //height: 100%;

    > .top {
      height: 80px;
      width: 100%;
      background: #4859c9;
      position: fixed;
      top: 0;
      z-index: 99;

      .boxOuter {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .logo {
        font-size: 30px;
        font-family: JBeiWeiKaiSu;
        align-items: center;
        display: flex;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 72px;

        p {
          margin: 0 10px;
        }
      }

      /*  .tab{
            display: flex;
            width: 400px;
            margin-left: -300px;
            justify-content: space-around;
            div{
                height: 30px;
                line-height: 30px;
                width: 100px;
                font-size: 14px;
                margin-left: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;

            }
            div:hover{
                background:rgba(12, 3, 7, .3);
                border-radius: 15px;
            }
        }*/

      .login {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;

        .showLogin:hover {
          color: #999999;
        }
      }
    }

    > .middle {
      width: 1200px;
      margin: 0px auto;
      //.slideShow{
      //    width: 80%;
      //    height: 400px;
      //    display: flex;
      //    padding-top: 20px;
      //    .left{
      //        width: 960px;
      //        flex-shrink: 0;
      //        margin-right: 18px;
      //    }
      //    .right{
      //        height: 360px;
      //        >.box-card{
      //            width: 220px;
      //            ::v-deep .el-card__body{
      //                padding: 10px;
      //            }
      //            ::v-deep .el-card__header{
      //                padding: 15px 20px;
      //            }
      //            .clearFix{
      //                .img{
      //                    width: 62px;
      //                    height: 62px;
      //                    margin: 29px auto;
      //                }
      //                .message{
      //                    text-align: center;
      //                    font-size: 14px;
      //                    font-family: Microsoft YaHei;
      //                    font-weight: 400;
      //                    color: #999999;
      //                }
      //                .btn{
      //                    padding-top: 21px;
      //                    padding-bottom: 10px;
      //                    text-align: center;
      //                }
      //            }
      //            .text{
      //                font-size: 14px;
      //                font-family: Microsoft YaHei;
      //                font-weight: 400;
      //                color: #999999;
      //                margin: 30px auto;
      //            }
      //        }
      //    }
      //}
    }

  }

  ::v-deep.el-dialog {
    height: auto;

    .el-dialog__body {
      .content {
        min-height: 350px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.publicity {
  margin-bottom: 40px;
}
</style>
